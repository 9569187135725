import React from 'react';
import './App.css';
import Terminal from 'terminal-in-react';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      welcomeMSG: `Hi, I'm Tristan Guckenberger. I'm a full stack devw`,
      links: [
        'https://gitlab.com/gcans',
        'https://www.linkedin.com/in/tristan-guckenberger-9761591a6/',
        'https://omnicollective.dev'
      ],
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          color: '#f7d75c',
          backgroundColor: '#3b2f04',
          overflowX: "hidden"
        }}
      > 
      
        <Terminal
          color='#f7d75c'
          backgroundColor='#3b2f04'
          outputColor='#a99342'
          prompt='#f7d75c'
          hideTopBar={true}
          allowTabs={false}
          promptSymbol={`$`}
          startState={'maximised'}
          style={{ fontWeight: "100", fontSize: "16px", fontFamily: `'monospace' !important` }}
          commands={{
            'omnicollective': () => {
              return (
                <div>
                  <span>go to omnicollective.dev {`->`} </span>
                  <a className='custom-button' href={`${this.state.links[2]}`} target='_blank'>omnicollective</a>
                </div>
              );
            },
            'linkedin': () => {
              // return window.open(`${this.state.links[0]}`, '_blank')
              return (
                <div>
                  <span>go to my linkedin profile {`->`} </span>
                  <a className='custom-button' href={`${this.state.links[1]}`} target='_blank'>linkedin</a>
                </div>
              );
            },
            'gitlab': () => {
              // return window.open(`${this.state.links[0]}`, '_blank')
              return (
                <div>
                <span>go to my gitlab profile {`->`} </span>
                <a className='custom-button' href={`${this.state.links[0]}`} target='_blank'>gitlab</a>
                </div>
              );
            }
          }}
          descriptions={{
            'omnicollective': `go to omnicollective`,
            'gitlab': `go to tristan's gitlab`,
            'linkedin': `go to tristan's linkedin`,
          }}
        >
          <div className='message'>
            <p>
              <span className='hili'>{`${'tristan:'} `} </span> 
              Hi, I'm a full stack software developer who specializes 
              in front end JavaScript libraries and frameworks 
              like <a href="https://reactjs.org/">React</a>, <a href="https://vuejs.org/">Vue</a>, 
              and <a href="https://svelte.dev/">Svelte</a>. I'm also the creator 
              of <a href="https://omnicollective.dev">omnicollective</a>, the 
              first open source programming-oriented education platform owned by everyone.
            </p>
            <p>type <span className='hili'>'help'</span> after the <span className='hili'>$</span> to view a list of available commands</p>
          </div>
        </Terminal>
      </div>
    );
  }
  
}

export default App;
